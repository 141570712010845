var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"addAddressModal","title":_vm.$t('address.popup.title'),"ok-title":_vm.$t('shared.button.submit'),"cancel-title":_vm.$t('shared.button.cancel'),"no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('close')},"ok":_vm.submitForm}},[_c('validation-observer',{ref:"newAddressValidation"},[_c('form',{attrs:{"id":"createAddressForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('address.label.name'),"rules":_vm.newAddressForm.searchable ? 'required|max:50' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('address.label.name'))+" ")]),_c('b-form-input',{attrs:{"id":"name","disabled":!_vm.newAddressForm.searchable,"type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('address.label.name'),"autocomplete":"chrome-off"},model:{value:(_vm.newAddressForm.name),callback:function ($$v) {_vm.$set(_vm.newAddressForm, "name", $$v)},expression:"newAddressForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('address.label.alias'),"rules":_vm.newAddressForm.searchable ? 'required|max:50' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"alias"}},[_vm._v(" "+_vm._s(_vm.$t('address.label.alias'))+" ")]),_c('b-form-input',{attrs:{"id":"alias","disabled":!_vm.newAddressForm.searchable,"type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('address.label.alias'),"autocomplete":"chrome-off"},model:{value:(_vm.newAddressForm.alias),callback:function ($$v) {_vm.$set(_vm.newAddressForm, "alias", $$v)},expression:"newAddressForm.alias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('address.label.street_number'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"address"}},[_vm._v(" "+_vm._s(_vm.$t('address.label.street_number'))+" ")]),_c('b-form-input',{attrs:{"id":"address","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('address.label.street_number'),"autocomplete":"chrome-off"},model:{value:(_vm.newAddressForm.address),callback:function ($$v) {_vm.$set(_vm.newAddressForm, "address", $$v)},expression:"newAddressForm.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('address.label.zip'),"rules":"required|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"zip"}},[_vm._v(" "+_vm._s(_vm.$t('address.label.zip'))+" ")]),_c('b-form-input',{attrs:{"id":"zip","type":"text","minlength":"3","maxlength":"10","placeholder":_vm.$t('address.label.zip'),"autocomplete":"chrome-off"},model:{value:(_vm.newAddressForm.zip),callback:function ($$v) {_vm.$set(_vm.newAddressForm, "zip", $$v)},expression:"newAddressForm.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('address.label.city'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"city"}},[_vm._v(" "+_vm._s(_vm.$t('address.label.city'))+" ")]),_c('b-form-input',{attrs:{"id":"city","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('address.label.city'),"autocomplete":"chrome-off"},model:{value:(_vm.newAddressForm.city),callback:function ($$v) {_vm.$set(_vm.newAddressForm, "city", $$v)},expression:"newAddressForm.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('b-form-checkbox',{on:{"input":_vm.handlePrivateChange},model:{value:(_vm.newAddressForm.private),callback:function ($$v) {_vm.$set(_vm.newAddressForm, "private", $$v)},expression:"newAddressForm.private"}},[_vm._v(" "+_vm._s(_vm.$t('order.label.address.is-private'))+" ")])],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"disabled":_vm.newAddressForm.private},on:{"change":_vm.handleSearchableChange},model:{value:(_vm.newAddressForm.searchable),callback:function ($$v) {_vm.$set(_vm.newAddressForm, "searchable", $$v)},expression:"newAddressForm.searchable"}},[_vm._v(" "+_vm._s(_vm.$t('order.label.address.searchable'))+" ")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }