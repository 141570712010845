<template>
  <div>
    <b-modal
      id="addAddressModal"
      :title="$t('address.popup.title')"
      :ok-title="$t('shared.button.submit')"
      :cancel-title="$t('shared.button.cancel')"
      no-close-on-backdrop
      @hidden="$emit('close')"
      @ok="submitForm"
    >

      <validation-observer ref="newAddressValidation">
        <form
          id="createAddressForm"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('address.label.name')"
                :rules="newAddressForm.searchable ? 'required|max:50' : ''"
              >
                <label for="name">
                  {{ $t('address.label.name') }}
                </label>
                <b-form-input
                  id="name"
                  v-model="newAddressForm.name"
                  :disabled="!newAddressForm.searchable"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('address.label.name')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('address.label.alias')"
                :rules="newAddressForm.searchable ? 'required|max:50' : ''"
              >
                <label for="alias">
                  {{ $t('address.label.alias') }}
                </label>
                <b-form-input
                  id="alias"
                  v-model="newAddressForm.alias"
                  :disabled="!newAddressForm.searchable"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('address.label.alias')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('address.label.street_number')"
                rules="required|min:3|max:50"
              >
                <label for="address">
                  {{ $t('address.label.street_number') }}
                </label>
                <b-form-input
                  id="address"
                  v-model="newAddressForm.address"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('address.label.street_number')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <b-row>
                <b-col
                  cols="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('address.label.zip')"
                    rules="required|min:3|max:10"
                  >
                    <label for="zip">
                      {{ $t('address.label.zip') }}
                    </label>
                    <b-form-input
                      id="zip"
                      v-model="newAddressForm.zip"
                      type="text"
                      minlength="3"
                      maxlength="10"
                      :placeholder="$t('address.label.zip')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="8"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('address.label.city')"
                    rules="required|min:3|max:50"
                  >
                    <label for="city">
                      {{ $t('address.label.city') }}
                    </label>
                    <b-form-input
                      id="city"
                      v-model="newAddressForm.city"
                      type="text"
                      minlength="3"
                      maxlength="50"
                      :placeholder="$t('address.label.city')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >

              <b-form-checkbox
                v-model="newAddressForm.private"
                @input="handlePrivateChange"
              >
                {{ $t('order.label.address.is-private') }}
              </b-form-checkbox>

            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >

              <b-form-checkbox
                v-model="newAddressForm.searchable"
                :disabled="newAddressForm.private"
                @change="handleSearchableChange"
              >
                {{ $t('order.label.address.searchable') }}
              </b-form-checkbox>

            </b-col>

          </b-row>
        </form>

      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import {
  BCol, BFormCheckbox, BFormInput, BModal, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, alphaNum, digits } from '@validations'

export default {
  components: {
    BModal,
    BFormInput,
    BCol,
    BRow,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      digits,
      newAddressForm: cloneNested(this.$store.getters['address/getAddressData']),
    }
  },
  computed: {
    statuses() {
      return cloneNested(this.$store.getters['address/getAddressStatuses'])
    },
  },
  mounted() {
    this.$bvModal.show('addAddressModal')
  },
  methods: {
    submitForm(event) {
      event.preventDefault()
      this.$refs.newAddressValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.$store.dispatch('address/addAddress', this.newAddressForm)
            .then(() => {
              this.$bvModal.hide('addAddressModal')
              this.$emit('submitted')
              window.toast.notify.success(this.$t('address.notify.add.success_title'))
            })
            .catch(err => parseRequestError(err))

          return true
        })
    },
    handleSearchableChange() {
      if (!this.newAddressForm.searchable) {
        this.newAddressForm.alias = ''
        this.newAddressForm.name = ''
      }
    },
    handlePrivateChange() {
      if (this.newAddressForm.private) {
        this.newAddressForm.searchable = false
      }
    },
  },
}
</script>
