<template>
  <div>

    <create-address-popup
      v-if="showAddModal"
      @close="showAddModal = false"
      @submitted="refreshData(); showAddModal = false;"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <table-top
        show-add-button
        show-search
        @change:limit="limitChanged"
        @change:query="queryChanged"
        @click:add="showAddModal = true "
      />

      <rc-overlay :show="loading">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="addresses"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="loading ? $t('shared.loading') : $t('shared.no-data') "
          :sort-by.sync="query.sortBy"
          :sort-desc.sync="query.isSortDirDesc"
        >

          <!-- Column: alias -->
          <template #cell(alias)="data">

            <b-link
              class="text-nowrap"
              :to="{ name: 'address-edit', params: { id: data.item.id } }"
            >
              {{ data.item.alias }}
            </b-link>

          </template>

          <!-- Column: name -->
          <template #cell(name)="data">

            <b-link
              class="text-nowrap"
              :to="{ name: 'address-edit', params: { id: data.item.id } }"
            >
              {{ data.item.name }}
            </b-link>

          </template>

          <!-- Column: city -->
          <template #cell(city)="data">

            <b-link
              class="text-nowrap"
              :to="{ name: 'address-edit', params: { id: data.item.id } }"
            >
              {{ data.item.city }}, {{ data.item.address }}
            </b-link>

          </template>

          <!-- Column: options -->
          <template #cell(options)="data">
            <b-badge
              v-if="data.item.searchable"
              variant="primary"
              class="mr-1"
            >
              {{ $t('address.label.is-searchable') }}
            </b-badge>

            <b-badge
              v-if="data.item.private"
              variant="secondary"
              class="mr-1"
            >
              {{ $t('address.label.is-private') }}
            </b-badge>

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <b-link
              class="text-nowrap text-secondary"
              :to="{ name: 'address-edit', params: { id: data.item.id } }"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editAddress(data.item.id)"
              />
            </b-link>

          </template>

        </b-table>
      </rc-overlay>

      <table-bottom
        :query="query"
        :total="total"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BLink, BTable,
} from 'bootstrap-vue'
import TableTop from '@/layouts/components/table/TableTop.vue'
import CreateAddressPopup from '@/views/addresses/popups/CreateAddressPopup.vue'
import TableBottom from '@/layouts/components/table/TableBottom.vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  components: {
    TableBottom,
    CreateAddressPopup,
    TableTop,
    RcOverlay,

    BCard,
    BTable,
    BLink,
  },
  data() {
    return {
      showAddModal: false,
      loading: false,

      total: 0,
      query: {
        sortBy: null,
        isSortDirDesc: false,
        query: null,
        limit: 10,
        page: 1,
      },

      tableColumns: [
        {
          label: this.$t('address.label.alias'),
          key: 'alias',
          sortable: true,
        },
        {
          label: this.$t('address.label.name'),
          key: 'name',
          sortable: true,
        },
        {
          label: this.$t('address.label.address'),
          key: 'city',
          sortable: true,
        },
        {
          label: '',
          key: 'options',
          sortable: true,
        },
        {
          label: this.$t('shared.actions'),
          key: 'actions',
          value: null,
          class: 'actions-column',
        },
      ],
    }
  },
  computed: {
    addresses() {
      return this.$store.getters['address/getAddresses']
    },
  },
  watch: {
    query: {
      handler() {
        this.refreshData()
      },
      deep: true,
    },
  },
  created() {
    this.refreshData()
  },
  methods: {
    refreshData() {
      this.loading = true
      const query = {
        q: this.query.query,
        sortField: this.query.sortBy,
        sortDir: this.query.isSortDirDesc ? 'desc' : 'asc',
        limit: this.query.limit,
        page: this.query.page,
      }

      Promise.all([this.$store.dispatch('address/fetchAddresses', query)])
        .then(res => {
          if (this.query.page > 1 && res[0].data.items.length === 0) {
            this.query.page = 1
          }
          this.total = res[0].data.total
          this.loading = false
        })
        .catch(err => console.log(err))
    },
    deleteAddress(id) {
      return id
    },
    editAddress(id) {
      this.$router.push(`/address/edit/${id}`)
    },
    limitChanged(newLimit) {
      this.query.limit = newLimit
    },
    queryChanged(newQuery) {
      this.query.query = newQuery
    },
  },
}
</script>

<style lang="scss">

.actions-column {
  width: 1rem;
}
</style>
